/* Estilo para la reportería */

  .report-header { height: 80px;  }
  // .report-footer, .report-footer-space { display:none}

  .report-header {
    position: fixed;
    top: 0;
    min-width: 96%;
//    width: calc(8.5in - 10mm);
   }
  
  .report {
    page-break-after: auto;
  }

//   .print:last-child {
//     page-break-after: auto;
// }
  
  @page {
    margin: 10mm;
    .page-number{
      content: counter(page)
    }
  }

  @media print {
    thead { display: table-header-group; }
    tfoot { display: table-footer-group;}
    tr { page-break-inside: avoid }
    .report-header-space { height: 80px}
    // .report-footer, .report-footer-space { display:block}
    button { display: none;}
    body {margin: 0;background-color: white; height: 99%; color:black;}
    
    .nobackground {background-color: white !important;}
    
    // .report-footer {
    //   position: fixed;
    //   bottom: 0;
    //   width: 100%;
    //   border-top: 1px solid black; /* for demo */
    //   background: yellow; /* for demo */
    // }
  }