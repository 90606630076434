body {
  margin: 0;
  // color: $dark;
  
  font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  //font-family: "Segoe UI","PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type=number] {
  text-align:right;
}

// label,tbody,thead {
//   color:$dark;
// }

// input,
// input:focus  {
//   background-color: $light;
// }

// .card-header {
//   background-color: $primary;
//   color: dark;
// }

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  font-size: 14px;
}

.fsprintable {
  display:none;
  //visibility: visible;
}
#fsprintable {
  visibility:hidden;
}

@media (max-width: 767.98px) {
  .sidebar {
    padding: 0;
    position: relative;
  }
}

@media print {
  // body * {
  //   visibility: hidden;
  // }
  .sidebar, #sidebarMenu  {
    display: none !important;
  }
  .fsprintable{
    display: block;
  }

  #fsprintable, #fsprintable * {
    visibility: visible;
    width:100%;
  }

  #fsprintable {
    position: absolute;
    left: 0;
    top: 0;
  }
  .noprint {
    display: none !important;
  }
}

.sidebar .nav-link {
  font-weight: normal;
  // font-size: 1rem;
}

.nav-item .nav-link:hover  {
  font-weight: 600;
  // transition: all 0.3s;
}

.frms {
  max-width: 1200px;
}

.sidebar .nav-link .icono {
  float:left;
  padding:0 1rem 0 0;
  margin: 0;
  // background-color: antiquewhite;
  // font-size: 1.2rem;
  line-height: 0.4rem;
}
.sidebar .nav-link .titulo {
  padding:0;
  margin: 0;
  width: 1px;
  float: left;
  white-space: nowrap;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  // box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .navbar-toggler {
  top: .25rem;
  right: 1rem;
}

// .form-control-dark {
  // color: #fff;
  // background-color: rgba(255, 255, 255, .1);
  // border-color: rgba(255, 255, 255, .1);
// }

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0; 
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}

.largerCheckbox {
  width: 70px;
  height: 30px;
}